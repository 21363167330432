<template>
    <b-card-group columns class="m-3">
        <b-card v-can="'Read_Clients||Read_Brokers||Read_Contacts'">
            <p class="h4 mb-2"><b-icon icon="people-fill"></b-icon> Files</p>
            <b-list-group flush>
                <b-list-group-item v-can="'Read_Clients'"><b-icon icon="arrow-right-circle" variant="primary"></b-icon> <b-link to="/clients">Clients</b-link></b-list-group-item>
                <b-list-group-item v-can="'Read_Brokers'"><b-icon icon="arrow-right-circle" variant="primary"></b-icon> <b-link to="/brokers">Brokers</b-link></b-list-group-item>
                <b-list-group-item v-can="'Read_Contacts'"><b-icon icon="arrow-right-circle" variant="primary"></b-icon> <b-link to="/contacts">Contacts</b-link></b-list-group-item>
            </b-list-group>
        </b-card>

        <b-card v-can="'Read_PhoneCalls||Read_DataIntegration'">
            <p class="h4 mb-2"><b-icon icon="people-fill" variant="info"></b-icon> Data Integration</p>
            <b-list-group flush>
                <b-list-group-item v-can="'Read_DataIntegration'"><b-icon icon="arrow-right-circle" variant="primary"></b-icon> <b-link to="/integrations">From Streetsync</b-link></b-list-group-item>
                <b-list-group-item v-can="'Read_PhoneCalls'"><b-icon icon="arrow-right-circle" variant="primary"></b-icon> <b-link to="/calls">Phone Calls</b-link></b-list-group-item>
            </b-list-group>
            <template #footer>
                <small v-can="'Read_DataIntegration'">{{lastDataIntegration}}</small>
            </template>
        </b-card>

        <b-card v-can="'Read_BillingParameters||Read_BillingPostalPrices||Read_Billing'">
            <p class="h4 mb-2"><b-icon icon="cash" variant="warning"></b-icon> Billing</p>
            <b-list-group flush>
                <b-list-group-item v-can="'Read_BillingParameters'"><b-icon icon="arrow-right-circle" variant="primary"></b-icon> <b-link to="/billing/parameter">Parameters</b-link></b-list-group-item>
                <b-list-group-item v-can="'Read_BillingPostalPrices'"><b-icon icon="arrow-right-circle" variant="primary"></b-icon> <b-link to="/billing/prices">Postal Prices</b-link></b-list-group-item>
                <b-list-group-item v-can="'Read_Billing'"><b-icon icon="arrow-right-circle" variant="primary"></b-icon> <b-link to="/billings">Billing</b-link></b-list-group-item>
            </b-list-group>
            <template #footer>
                <small v-can="'Read_Billing'"><b-link :to="lastBillingUrl">{{lastBilling}}</b-link></small>
            </template>
        </b-card>

        <b-card v-can="'Read_PayrollParameters||Read_PayrollPostalPrices||Read_Payroll'">
            <p class="h4 mb-2"><b-icon icon="truck" variant="danger"></b-icon> Payroll</p>
            <b-list-group flush>
                <b-list-group-item v-can="'Read_PayrollParameters'"><b-icon icon="arrow-right-circle" variant="primary"></b-icon> <b-link to="/payroll/parameter">Parameters</b-link></b-list-group-item>
                <b-list-group-item v-can="'Read_PayrollPostalPrices'"><b-icon icon="arrow-right-circle" variant="primary"></b-icon> <b-link to="/payroll/prices">Postal Prices</b-link></b-list-group-item>
                <b-list-group-item v-can="'Read_Payroll'"><b-icon icon="arrow-right-circle" variant="primary"></b-icon> <b-link to="/payrolls">Payroll</b-link></b-list-group-item>
            </b-list-group>
            <template #footer>
                <small v-can="'Read_Payroll'"><b-link :to="lastPayrollUrl">{{lastPayroll}}</b-link></small>
            </template>
        </b-card>
    </b-card-group>
</template>

<script>
import axios from 'axios'
import Basic from '../mixins/Basic.js'
export default {
    mixins: [Basic],
    name: 'Wizard',
    data() {
        return {
            year: this.setYear(),
            lastBilling: '',
            lastBillingUrl: '',
            lastPayroll: '',
            lastPayrollUrl: '',
            lastDataIntegration: ''
        };
    },
    methods: {
        setYear(){
            var date = new Date();
            return date.getFullYear();
        },
        async getData(){
            await this.getFullMessage('billing')
            await this.getFullMessage('payroll')
            await this.getFullMessage('integration')
        },
        async getFullMessage(service){
            try {
                let lastDirty = await axios.get(service+"/last")
                var user = lastDirty.data[0].data.addBy
                var date = this.cleanDateTime(lastDirty.data[0].data.addDate)
                var id = lastDirty.data[0].data.id
                if(service=='billing'){
                    this.lastBillingUrl = "/"+service+"/"+id
                    this.lastBilling = "Last billing created by user " + user + " on " + date + ".";
                } else if(service=='payroll'){
                    this.lastPayrollUrl = "/"+service+"/"+id
                    this.lastPayroll = "Last payroll created by user " + user + " on " + date + ".";
                } else if(service=='integration'){
                    this.lastDataIntegration = "Last integration created on " + date + ".";
                } else {
                    return
                }
            } catch (error) {
                console.log(error);
            }
        },
    },
    created(){
        this.getData()
    }
}
</script>